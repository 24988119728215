import * as R from "ramda";
import { useState } from "react";

export interface SpokenText {
  speaker?: string;
  text: string;
}
export type TranscriptionSection = [string, SpokenText[]];

export interface Transcription {
  packets?: object;
  unanswered_questions?: string[];
}

export interface Packet {
  header: string;
  content: SpokenText[];
}

// use this when testing the layout.
// eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
const sampleTranscription: TranscriptionSection[] = [
  [
    "Introduction",
    [
      { text: "Welcome Mr. Patient, how are you", speaker: "Doctor" },
      { text: "Well Dr. Pierce, I'm not feeling well", speaker: "Patient" },
    ],
  ],
  [
    "Cough",
    [
      { text: "What is the problem?", speaker: "Doctor" },
      {
        text: "I have a really bad cough, it keeps my up at night. I can't sleep at all.",
        speaker: "Patient",
      },
      { text: "Is it a dry cough?", speaker: "Doctor" },
      {
        text: "No, I'm bringing up a lot of yellow phlegm.",
        speaker: "Patient",
      },
      { text: "Do you have a sore throat?", speaker: "Doctor" },
      { text: "A little bit.", speaker: "Patient" },
    ],
  ],
  [
    "Fever",
    [
      { text: "What about fever?", speaker: "Doctor" },
      { text: "I feel a little warm.", speaker: "Patient" },
    ],
  ],
];

function useTranscriptionState() {
  const [transcription, setTranscription] = useState<TranscriptionSection[]>(
    //sampleTranscription
    []
  );
  const [questions, setQuestions] = useState<string[] | null>([]);
  //   "What is your name?",
  //   "What is your quest?",
  //   "What is the capital of Assyria?",
  //   "What is your favorite color?",
  //   "What is the airspeed velocity of an unladen swallow?",
  //   "Who are you who are so wise in the ways of science?",
  //   "Help! Help! I'm being repressed!",
  // ]);

  function appendJsonToTranscription(json: string) {
    console.log(`we received json from webhook: ${json}`);
    const newTranscriptionSection = JSON.parse(json) as Transcription;
    setQuestions(newTranscriptionSection.unanswered_questions || null);
    const packets = R.toPairs(
      newTranscriptionSection.packets || {}
    ) as TranscriptionSection[];
    setTranscription([...transcription, ...packets]);
  }

  function appendArrayToTranscription(packets: Packet[], questions: string[]) { //[explanation]: this function takes in questions and packets and appends the packets to the transcription and sets the questions to the questions passed in
    console.log(
      ` we will add packets ${JSON.stringify(packets)} to transcription`
    );

    const packetArray = packets
      .map((packet) => [packet.header, packet.content] as TranscriptionSection)
      .filter((packet) => packet[1].length > 0);

    // const newPackets: TranscriptionSection[] = packetArray.map((packet) => {
    //   const spokenTexts = packet[1].map((segment) => {
    //     const spokenText = { text: segment} as SpokenText;
    //     return spokenText;
    //   });

    //   return [packet[0], spokenTexts];
    // });

    // const lines = segments.map((segment, ix) =>
    //   textProps(segment));

    // const newPacket = ["0", lines] as TranscriptionSection;
    // console.log(`new packet is ${JSON.stringify(newPacket)}`)

    // const newPackets = segments.map((segment, ix) => [
    //   `${ix}`,
    //   [textProps(segment)],
    // ]) as TranscriptionSection[];
    console.log(
      `combine ${JSON.stringify(transcription)} with ${JSON.stringify(
        packetArray
      )}`
    );
    setTranscription([...transcription, ...packetArray]); 
    //setTranscription(packetArray);
    setQuestions(questions || null);
  }

  return {
    transcription,
    questions,
    appendJsonToTranscription,
    appendArrayToTranscription,
  };
}

export default useTranscriptionState;
