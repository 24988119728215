import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any[] = [];

const deletedMedicinesSlice = createSlice({
  name: "deletedMedicines",
  initialState,
  reducers: {
    addDeletedMedicine(state, action: PayloadAction<any>) {
      state.push(action.payload);
    },
  },
});

export default deletedMedicinesSlice.reducer;
export const { addDeletedMedicine } = deletedMedicinesSlice.actions;