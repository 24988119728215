import { useDispatch, useSelector } from "react-redux";
import { addDeletedMedicine } from "../state/DeletedMedicinesSlice";
import { RootState } from "../state/reducers";

function useDeletedMedicine() {
  const dispatch = useDispatch();
  const deletedMedicines = useSelector((state: RootState) => {
    return state.deletedMedicines;
  });

    const deletedMedicine = (medicine: string, reason: string) => {
        dispatch(addDeletedMedicine({ "med" : medicine, "res" : reason}));
    };  

  return {
    deletedMedicines,
    deletedMedicine
  };
}

export default useDeletedMedicine;
